"use client";
const { createTheme } = require("@mui/material");
import { webColors } from "./Constants";
export const themeFont = createTheme({
  palette: {
    primary: {
      main: webColors.primary,
    },
    secondary: {
      main: webColors.secondary,
    },
    pale: {
      main: webColors.pale,
    },
    orange: {
      main: webColors.orange,
    },
  },
  typography: {
    fontFamily: "inherit",
  },
});
